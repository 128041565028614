import {onLoad} from "../base/onLoad";

onLoad(() => {
   const inputContainers = document.querySelectorAll('.input-container');

   inputContainers.forEach(container => {
      const input = container.querySelector('input, textarea');
      const filledContainerClass = 'input-container--filled';
      const filledInputClass = 'input-container__input--filled';

      if(!input) {
          return;
      }

      input.addEventListener('input', () => {
         if (input.value.length > 0) {
            container.classList.add(filledContainerClass);
            input.classList.add(filledInputClass);
         } else {
            container.classList.remove(filledContainerClass);
            input.classList.remove(filledInputClass);
         }
      });

      const event = new Event('input');
      input.dispatchEvent(event);
   });
});

onLoad(() => {
    const otherDataCheckbox = document.querySelector('[name="different-data"]');

    if(!otherDataCheckbox) {
        return;
    }

    function render() {
       const data_preview = document.querySelector('.data-preview');
       const use_other_data = document.querySelector('.use-other-data');

       const isChecked = otherDataCheckbox.checked;

       if(!isChecked) {
           data_preview.classList.remove('hidden');
           use_other_data.classList.add('hidden');
       } else {
           data_preview.classList.add('hidden');
           use_other_data.classList.remove('hidden');
       }
    }

    otherDataCheckbox.addEventListener('change', render);
    render();
});

onLoad(() => {
   const inputControlGroups = document.querySelectorAll('.input-number-group');

   inputControlGroups.forEach(group => {
       const plus = group.querySelector('.input-number-control-increase');
       const minus = group.querySelector('.input-number-control-decrease');
       const input = group.querySelector('input');

       const hasMin = input.hasAttribute('min');
       const hasMax = input.hasAttribute('max');

       const min = parseInt(input.getAttribute('min'));
       const max = parseInt(input.getAttribute('max'));

       plus.addEventListener('click', (e) => {
           e.preventDefault();

           if(hasMax && input.value >= max) {
               return;
           }

           input.value = parseInt(input.value) + 1;
       });

       minus.addEventListener('click', (e) => {
           e.preventDefault();

           if(hasMin && input.value <= min) {
               return;
           }

           input.value = parseInt(input.value) - 1;
       });
   })
});

onLoad(() => {
   const forms = document.querySelectorAll('.smart-form');

   forms.forEach(form => {
       let submit = form.querySelector('button[type="submit"]');
       let inputs = form.querySelectorAll('input, textarea');

       const checkValidity = () => {
           let valid = true;

           inputs.forEach(input => {
               const isValid = input.checkValidity();

               if(!isValid) {
                   valid = false;
               }
           });

           return valid;
       };

       const check = () => {
           const valid = checkValidity();

           if(valid) {
               submit.removeAttribute('disabled');
           } else {
               submit.setAttribute('disabled', 'disabled');
           }
       }

       inputs.forEach(input => {
           input.addEventListener('input', check);
       });
       check();

   })
});